export const Products = [
    {
        id: 1,
        modelno: "MT02 Dx PLUS",
        name: "2 in 1 Wire Stripper & Cutter",
        img: "02DX PLUS-02.webp",
        headname: "Wire Stripper",
        category: "Wire Stripper",
        OVERALLLENGTHTitle: "Overall length: ",
        OVERALLLENGTHT: "180mm.",
        CapacityTitle: "Capacity:",
        capacity: " Cu Wire dia 14-24 AWG / 0.5mm² - 6mm².",
        URL: "wirestriperbanner.webp",
        URLname: "MT02-Dx_PLUS",
        material: "Plastic.",
        handeltitle: "Handle:",
        handel: "Nylon.",
        ApplicatonTitle: "Application: ",
        Applicaton:
            "Strip and cut wires/cables accurately on both solid core & stranded wires.",
        features:
            "1. New improved engineering reinforced plastic material enhanced performance.",
        features1:
            "2. Suitable for use in tight and narrow spaces with stripping Length capacity up to 20mm.",
    },
    {
        id: 2,
        modelno: "02dx",
        name: "Wire Stripper",
        img: "02dx.webp",
        headname: "Wire Stripper",
        category: "Wire Stripper",
        OVERALLLENGTHTitle: "Overall length: ",
        OVERALLLENGTHT: "180mm.",
        CapacityTitle: "Capacity:",
        capacity: " Cu Wire dia 14-24 AWG / 0.5mm² - 7mm².",
        URL: "wirestriperbanner.webp",
        URLname: "02dx",
        material: "Plastic.",
        handeltitle: "Handle:",
        handel: "Nylon.",
        ApplicatonTitle: "Application: ",
        Applicaton:
            " A unique tool to strip and cut insulation on copper wire ranging from 0.5mm to 2.5mm or 6mm diameter.",
        features:
            "Suitable for electronics, aviation, automobile, and general maintenance.",
    },
    {
        id: 3,
        modelno: "150B",
        name: "Wire Stripper & Cutter",
        img: "150B.webp",
        headname: "Wire Stripper",
        category: "Wire Stripper",
        OVERALLLENGTHTitle: "Overall length: ",
        OVERALLLENGTHT: "140mm.",
        CapacityTitle: "Capacity:",
        capacity: "Cu Wire upto 4.0mm².",
        URL: "wirestriperbanner.webp",
        URLname: "150B",
        material: "High grade tempered Steel.",
        handeltitle: "Handle:",
        handel: "PVC Cushioned grips.",
        ApplicatonTitle: "Application: ",
        Applicaton: "Suitable to cut & strip electrical wires.",
        features: "With screw gauge adjuster.",
    },
    {
        id: 4,
        modelno: "150C Champion",
        name: "Heavy Duty Wire Stripper & Cutter",
        img: "150C CHAMPION.webp",
        headname: "Wire Stripper",
        category: "Wire Stripper",
        OVERALLLENGTHTitle: "Overall length: ",
        OVERALLLENGTHT: "140mm.",
        CapacityTitle: "Capacity:",
        capacity: "Cu Wire upto 4.0mm².",
        URL: "wirestriperbanner.webp",
        URLname: "150C_Champion",
        material: "PVC Cushioned grips.",
        handeltitle: "Handle:",
        handel: "Acetate with Extra hand grip.",
        ApplicatonTitle: "Application: ",
        Applicaton: "Suitable to cut & strip electrical wires.",
        features: "1. Wire stripper and cutter with a heavy-duty long handle.",
        features1: "2. Heavy-duty construction with precision cutting edges.",
    },
    {
        id: 5,
        modelno: "68C",
        name: "Wire Stripper & Cutter",
        img: "68C.webp",
        headname: "Wire Stripper",
        category: "Wire Stripper",
        OVERALLLENGTHTitle: "Overall length: ",
        OVERALLLENGTHT: "140mm.",
        CapacityTitle: "Capacity:",
        capacity: "Cu Wire upto 4.0mm².",
        URL: "wirestriperbanner.webp",
        URLname: "68_C",
        material: "High grade tempered Steel.",
        handeltitle: "Handle:",
        handel: "PVC Cushioned grips.",
        ApplicatonTitle: "Application: ",
        Applicaton: "Suitable to cut & strip electrical wires.",
        features:
            "Wire stripper and cutter with a spring and screw gauge adjuster.",
    },
    {
        id: 6,
        modelno: "68D",
        name: "Wire Stripper & Cutter",
        img: "68D.webp",
        headname: "Wire Stripper",
        category: "Wire Stripper",
        OVERALLLENGTHTitle: "Overall length: ",
        OVERALLLENGTHT: "140mm.",
        CapacityTitle: "Capacity:",
        capacity: "Cu Wire upto 4.0mm².",
        URL: "wirestriperbanner.webp",
        URLname: "68_D",
        material: "High grade tempered Steel.",
        handeltitle: "Handle:",
        handel: "PVC Cushioned grips.",
        ApplicatonTitle: "Application: ",
        Applicaton: " Suitable to cut & strip electrical wires.",
        features:
            "1. Wire stripper and cutter with a spring and screw gauge adjuster.",
        features1: "2.  Duly grounded mirrio finish blades.",
    },

    //   screwdriver

    {
        id: 10,
        name: "2-in-1 Reversible Screwdriver",
        Series: "R 6000 ",
        img: "R 6100.webp",
        headname: "Screw Driver",
        material: "High Grade Alloy Steel.",
        handeltitle: "Handle: ",
        handel: "CA.",
        detailstitle: "Details",
        modelnoTitle: "Model No",
        tipsizetitle: "Tip Size",
        shanksizetitle: "Shank Size",
        shanklengthTitle: "Shank Length",
        mn1: "R 6100 ",
        mn2: "R 6150",
        mn3: "R 6200 ",
        mn4: "R 6250",
        mn5: "R 6300",
        s1: "PH2/S6",
        s2: "PH2/S6",
        s3: "PH2/S6",
        s4: "PH2/S6",
        s5: "PH2/S6",
        ss1: "6.0MM HEXAGON",
        ss2: "6.0MM HEXAGON",
        ss3: "6.0MM HEXAGON",
        ss4: "6.0MM HEXAGON",
        ss5: "6.0MM HEXAGON",
        sl1: "100MM ",
        sl2: "150MM ",
        sl3: "200MM ",
        sl4: "250MM ",
        sl5: "300MM ",
        URL: "screwdriverbanner.webp",
        URLname: "R-6000",
        category: "Screw Driver",
        finish: "Chrome Plated. Brown Oxide coated.",
        finishTitle: "Finish: ",
        features: "1. 2-in-1 screwdriver: Philips end PH2 and flat end 6mm.",
        features1: "2. Made of hexagon high-grade alloy steel.",
        features2: "3. Duly hardened and tempered.",
        features3:
            "4. Hexagon Shaft Screwdriver bit for applying rotating torque with a spanner for very tight screws.",
    },
    {
        id: 11,
        name: " Insulated 2-in-1 Reversible Screwdriver",
        img: "R 6100 i.webp",
        headname: "Screw Driver",
        material: "High Grade Alloy Steel.",
        handeltitle: "Handle: ",
        handel: "CA.",
        detailstitle: "Details",
        modelnoTitle: "Model No",
        tipsizetitle: "Tip Size",
        shanksizetitle: "Shank Size",
        shanklengthTitle: "Shank Length",
        mn1: "R 6100 i ",
        mn2: "R 6150 i",
        mn3: "R 6250  i",
        s1: "PH2/S6",
        s2: "PH2/S6",
        s3: "PH2/S6",
        ss1: "6.0MM HEXAGON",
        ss2: "6.0MM HEXAGON",
        ss3: "6.0MM HEXAGON",
        sl1: "100MM ",
        sl2: "150MM ",
        sl3: "200MM ",
        URL: "screwdriverbanner.webp",
        URLname: "R_6000-i",
        category: "Screw Driver",
        finish: "Chrome Plated. Brown Oxide coated.",
        finishTitle: "Finish: ",
        Series: "R 6000 i",
        features: "1. 2-in-1 screwdriver Philips end PH2 and flat end 6mm.",
        features1: "2. Made of hexagon high-grade alloy steel.",
        features2: "3.Duly hardened and tempered.",
        features3: "4.Insulated for electrical use.",
        features4:
            "5. Hexagon Shaft Screwdriver bit for applying rotating torque with a spanner for very tight screws.",
    },
    {
        id: 12,
        img: "TR 6150.webp",
        name: "2-in-1 Reversible T-Handle Screwdriver",
        Series: "TR 6000",
        headname: "Screw Driver",
        material: "High Grade Alloy Steel.",
        handeltitle: "Handle: ",
        handel: "CA.",
        detailstitle: "Details",
        modelnoTitle: "Model No",
        tipsizetitle: "Tip Size",
        shanksizetitle: "Shank Size",
        shanklengthTitle: "Shank Length",
        mn1: "TR 6150  ",
        mn2: "TR 6250",
        s1: "PH2/S6",
        s2: "PH2/S6",
        ss1: "6.0MM HEXAGON",
        ss2: "6.0MM HEXAGON",
        sl1: "150MM ",
        sl2: "250MM ",
        URL: "screwdriverbanner.webp",
        URLname: "TR-6000",
        category: "Screw Driver",
        finishTitle: "Finish: ",
        finish: "Finish: Chrome P lated, black tipped shank.",
        features: "1.2-in-1 screwdriver: Philips end PH2 and flat end 6mm.",
        features1: "2.Made of hexagon high-grade alloy steel.",
        features2: "3.Duly hardened and tempered.",
        features3:
            "4.T-driver handle configuration allows higher torque application with minimal effort.",
        features4:
            "5.Available in plain chrome plated finish and also color-coded three-tone finish.",
        features5:
            "6. Hexagon Shaft Screwdriver bit for applying rotating torque with a spanner for very tight screws.",
    },

    {
        id: 17,
        name: "4-in-1 (Tee-Driver) Reversible Screwdriver",
        Series: "TRB 6000",
        headname: "Screw Driver",
        material: "High Grade Alloy Steel.",
        handeltitle: "Handle: ",
        handel: "CA.",
        detailstitle: "Details",
        modelnoTitle: "Model No",
        tipsizetitle: "Tip Size",
        shanksizetitle: "Shank Size",
        shanklengthTitle: "Shank Length",
        mn1: "TRB 6150",
        mn2: "TRB 6250",
        s1: "PH2/S5",
        s2: "PH2/S5",
        ss1: "6.0MM HEXAGON",
        ss2: "6.0MM HEXAGON",
        sl1: "200 MM",
        sl2: "300 MM",
        img: "TRB 6150.webp",
        URL: "screwdriverbanner.webp",
        URLname: "TRB-6000",
        category: "Screw Driver",
        finish: "Finish: ",
        finishTitle: "Chrome Plated, black tipped shank",
        features:
            "1. Unique compact design, the only one in the world to offer tee-orientation in a stubby screwdriver.",
        features1:
            "2.Four-head screwdriver in one tool, offering better value for money spent.",
        features2:
            "3.Two, two-way reversible screwdriver - one fixed and one reversible.",
        features3:
            "4. Tee-orientation offers better leverage and requires less effort while working.",
        features4: "5. 2-in-1 screwdriver: Philips end PH2 and flat end 6mm.",
        features5: "6. Made of hexagon high-grade alloy steel.",
        features6: "7. Duly hardened and tempered.",
        features7:
            "8. Hexagon Shaft Screwdriver bit for applying rotating torque with a spanner for very tight screws.",
    },
    {
        id: 18,
        name: "2-in-1 Reversible Screwdriver",
        Series: "R 5000 ",
        headname: "Screw Driver",
        material: "High Grade Alloy Steel.",
        handeltitle: "Handle: ",
        handel: "CA.",
        detailstitle: "Details",
        modelnoTitle: "Model No",
        tipsizetitle: "Tip Size",
        shanksizetitle: "Shank Size",
        shanklengthTitle: "Shank Length",
        mn1: "R 5100",
        mn2: "R 5150",
        s1: "PH1/S5     ",
        s2: "PH1/S5     ",
        ss1: "5.0MM HEXAGON",
        ss2: "5.0MM HEXAGON",
        sl1: "100 MM",
        sl2: "150 MM",
        img: "R 5100.webp",
        URL: "screwdriverbanner.webp",
        URLname: "R-5000",
        category: "Screw Driver",
        finish: "Chrome Plated. Brown Oxide coated.",
        finishTitle: "Finish: ",
        features: "1.2-in-1 screwdriver: Philips end PH1 and flat end 5mm.",
        features1: "2.Made of hexagon high-grade alloy steel.",
        features2: "3.Duly hardened and tempered.",
        features3:
            "4. Hexagon Shaft Screwdriver bit for applying rotating torque with a spanner for very tight screws.",
    },
    {
        id: 19,
        name: " 2-in-1 Reversible Screwdriver",
        Series: "R 4000",
        headname: "Screw Driver",
        material: "High Grade Alloy Steel.",
        handeltitle: "Handle: ",
        handel: "CA.",
        detailstitle: "Details",
        modelnoTitle: "Model No",
        tipsizetitle: "Tip Size",
        shanksizetitle: "Shank Size",
        shanklengthTitle: "Shank Length",
        mn1: "R 4075",
        s1: "PH0/S3",
        ss1: "54.0MM HEXAGON",
        sl1: "75 MM ",
        img: "R 4075.webp",
        URL: "screwdriverbanner.webp",
        URLname: "R-4000",
        category: "Screw Driver",
        finish: "Chrome Plated. Brown Oxide coated.",
        finishTitle: "Finish: ",
        features: "1.2 IN ONE SCREW DRIVER: PHILIP END PH-0 & FLAT END 4mm.",
        features1: "2.Made of hexagon high-grade alloy steel.",
        features2: "3.Duly hardened and tempered.",
        features3:
            "4. Hexagon Shaft Screwdriver bit for applying rotating torque with a spanner for very tight screws.",
    },
    {
        id: 20,
        name: "TST Series ",
        Series: "R 6000 TST",
        headname: "Screw Driver",
        material: "High Grade Alloy Steel.",
        handeltitle: "Handle: ",
        handel: "CA.",
        detailstitle: "Details",
        modelnoTitle: "Model No",
        tipsizetitle: "Tip Size",
        shanksizetitle: "Shank Size",
        shanklengthTitle: "Shank Length",
        mn1: "R 6100 TST",
        mn2: "R 6150 TST",
        mn3: "R 6250 TST",
        s1: "PPH2/S6",
        s2: "PPH2/S6",
        s3: "PPH2/S6",
        ss1: "54.0MM HEXAGON",
        ss2: "54.0MM HEXAGON",
        ss3: "54.0MM HEXAGON",
        sl1: "100 MM ",
        sl2: "150 MM ",
        sl3: "250 MM ",
        img: "R 6100 TST.webp",
        URL: "screwdriverbanner.webp",
        URLname: "R-6000-TST",
        category: "Screw Driver",
        finish: "Chrome Plated. Brown Oxide coated.",
        finishTitle: "Finish: ",
        features: "1.2-in-1 screwdriver Blade: Philips end PH-2 and flat end 6mm.",
        features1: "2.Made of hexagon high-grade alloy steel.",
        features2: "3.Duly hardened and tempered.",
        features3: "4.Handle made of angel-blue color for better contrast.",
        features4:
            "5.Screwdriver handle with neon bulb for voltage detection up to 500 volts.",
        features5: "6.Insulated for safety against electrical shocks.",
        features6:
            "7. Hexagon Shaft Screwdriver bit for applying rotating torque with a spanner for very tight screws.",
    },
    {
        id: 67,
        modelno: "015 SS",
        URLname: "015-SS",
        name: "SS Mini Nipper",
        img: "015-SS.webp",
        headname: "Stainless Steel Tools",
        sizetitle: "Size",
        size: "115 mm.",
        CapacityTitle: "Capacity: ",
        capacity: "Cut cu & all Wires upto 1.3 mm Dia",
        material: "Stainless Steel.",
        handeltitle: "Handle: ",
        handel: "TPE.",
        ApplicatonTitle: "Application: ",
        Applicaton:
            "Designed to cut soft copper and aluminium Wires up to 1.3 mm diameter.",
        URL: "Stainless Steel Toolsbanner.webp",
        category: "Stainless Steel Tools",
        features: "1.Palm type mini diagonal nipper.",
        features2: "2.Razor sharp cutting edges duly ground and polished.",
    },
    {
        id: 68,
        modelno: "011 HD",
        URLname: "011-HD",
        name: "",
        img: "011-HD.webp",
        headname: "Stainless Steel Tools",
        sizetitle: "Size",
        size: "115 mm.",
        CapacityTitle: "Capacity: ",
        capacity: "Cut: Soft Cut & AL Wires upto 2.0 mm Dia.",
        material: "High Grade Stainless Steel.",
        handeltitle: "Handle: ",
        handel: "TPE.",
        ApplicatonTitle: "Application: ",
        Applicaton:
            "Suitable to cut copper and aluminium Wires up to 2.0 mm diameter.",
        URL: "Stainless Steel Toolsbanner.webp",
        category: "Stainless Steel Tools",
        cautionTitle: "Caution: ",
        caution: "Do not cut steel wire.",
        features: "1.Most useful for jewellery industry.",
        features1: "2.Long life screw joint (scrivets).",
        features2: "3.Ergonomic and long handle grips.",
        features3: "4.Safety lock when not in use.",
    },

    {
        id: 70,
        modelno: "111 SS",
        URLname: "111-SS",
        name: "SS Palm Nipper",
        img: "111-SS.webp",
        headname: "Stainless Steel Tools",
        sizetitle: "Size",
        size: "112 mm.",
        CapacityTitle: "Capacity: ",
        capacity: "Cut: Soft Cut & AL Wires upto 1.5 mm Dia.",
        material: "Stainless Steel.",
        handeltitle: "Handle: ",
        handel: "PVC.",
        // ApplicatonTitle: "Application: ",
        // Applicaton: "",
        URL: "Stainless Steel Toolsbanner.webp",
        category: "Stainless Steel Tools",
        features: "Most useful for jewellery industry.  ",
    },
    {
        id: 71,
        modelno: "CC-100 SS",
        URLname: "CC-100-SS",
        name: "SS Cable Cutter",
        img: "CC-100-SS.webp",
        headname: "Stainless Steel Tools",
        CapacityTitle: "Capacity: ",
        capacity: "Cut 12mm Cable.",
        material: "Stainless Steel.",
        handeltitle: "Handle: ",
        handel: "TPE.",
        ApplicatonTitle: "Application: ",
        Applicaton: "SS cable cutter for 12 mm cable.",
        URL: "Stainless Steel Toolsbanner.webp",
        category: "Stainless Steel Tools",
        features: "Safety lock when not in use.",
    },
    {
        id: 72,
        modelno: "CC-200 SS",
        URLname: "CC-200-SS",
        name: "SS Cable Cutter",
        img: "CC-200-SS.webp",
        headname: "Stainless Steel Tools",
        sizetitle: "Size",
        size: "170mm.",
        CapacityTitle: "Capacity: ",
        capacity: "Cut 10mm Cable.",
        material: "Stainless Steel.",
        handeltitle: "Handle: ",
        handel: "TPE.",
        ApplicatonTitle: "Application: ",
        Applicaton: "SS cable cutter for 10 mm cable.",
        URL: "Stainless Steel Toolsbanner.webp",
        category: "Stainless Steel Tools",
        features: "Safety lock when not in use.",
    },
    {
        id: 73,
        modelno: "901 SS",
        URLname: "901 SS",
        name: "SS Multipurpose Shear, Straight Type",
        img: "901-SS.webp",
        headname: "Stainless Steel Tools",
        sizetitle: "Size",
        size: "190mm.",
        // CapacityTitle: "Capacity: ",
        // capacity: "",
        material: "Highy grade stainless Steel",
        handeltitle: "Handle: ",
        handel: "Acetate.",
        ApplicatonTitle: "Application: ",
        Applicaton:
            "Cut/shear wide variety of material like electrical Wires, cord, small tree branches, nylon ropes, cardboard, etc.",
        URL: "Stainless Steel Toolsbanner.webp",
        category: "Stainless Steel Tools",
    },
    {
        id: 74,
        modelno: "902 SS",
        URLname: "902 SS",
        name: "SS Multipurpose Shear, Straight Type",
        img: "902-SS.webp",
        headname: "Stainless Steel Tools",
        sizetitle: "Size",
        size: "180mm.",
        // CapacityTitle: "Capacity: ",
        // capacity: "",
        material: "Highy grade stainless Steel",
        // handeltitle: "Handle: ",
        // handel: "",
        ApplicatonTitle: "Application: ",
        Applicaton:
            "  Cut/shear wide variety of material like electrical Wires, cord, small tree branches, nylon ropes, cardboard, etc.",
        URL: "Stainless Steel Toolsbanner.webp",
        category: "Stainless Steel Tools",
    },

    // NIPPERS & MICRO- SHEARS
    {
        id: 75,
        modelno: "07",
        URLname: "07",
        name: "Nipper Cutter",
        img: "07.webp",
        headname: "Nippers & Micro- Shears",
        sizetitle: "Size: ",
        size: "120mm.",
        CapacityTitle: "Capacity: ",
        capacity: "Cut cu & all Conductors form 0.8mm To 1.2mm.",
        material: "Alloy Steel.",
        handeltitle: "Handle: ",
        handel: "Dual tone 100% lead free polystyrene.",
        ApplicatonTitle: "Application: ",
        Applicaton:
            "Versatile tool for cutting copper and aluminium conductors form 0.8mm to 1.2mm.",
        category: " Nippers & Micro- Shears",
        URL: "micro shears banner.webp",
        features: "Large ergonomic handles.",
    },
    {
        id: 76,
        modelno: "07AElectra",
        URLname: "07-Electra",
        name: "Hard Wire Nipper Cutter",
        img: "07AElectra.webp",
        headname: "Nippers & Micro- Shears",
        sizetitle: "Size: ",
        size: "120mm.",
        CapacityTitle: "Capacity: ",
        capacity:
            "Cut Iron Wire upto 1.2 mm Dia  2. Cut Conductors from 1.0mm To 1.6mm Dia.",
        material: "Alloy Steel.",
        handeltitle: "Handle: ",
        handel: "Dual tone 100% lead free polystyrene.",
        ApplicatonTitle: "Application: ",
        Applicaton:
            "Versatile tool for cutting copper and aluminium conductors form 0.8mm to 1.2mm.",
        category: " Nippers & Micro- Shears",
        URL: "micro shears banner.webp",
        features: "Large ergonomic handles.",
    },
    {
        id: 77,
        modelno: "06",
        URLname: "06",
        name: " Microshear",
        img: "06.webp",
        headname: "Nippers & Micro- Shears",
        sizetitle: "Size: ",
        size: "125mm.",
        CapacityTitle: "Capacity: ",
        capacity: "Cut Wires from 0.8mm To 1.4mm.",
        material: "Alloy Steel.",
        handeltitle: "Handle: ",
        handel: "PVC grips.",
        ApplicatonTitle: "Application: ",
        Applicaton: "Can cut CU wires.",
        category: " Nippers & Micro- Shears",
        URL: "micro shears banner.webp",
    },
    {
        id: 78,
        modelno: "MT010",
        URLname: "MT010",
        name: "Microcutter",
        img: "MT010.webp",
        headname: "Nippers & Micro- Shears",
        sizetitle: "Size: ",
        size: "125 mm.",
        CapacityTitle: "Capacity: ",
        capacity: "Cut Wires from 0.8mm To 1.2mm.",
        material: "Alloy Steel.",
        handeltitle: "Handle: ",
        handel: "PVC grips.",
        ApplicatonTitle: "Application: ",
        Applicaton: "Can cut CU wires.",
        category: " Nippers & Micro- Shears",
        URL: "micro shears banner.webp",
    },

    // pillers
    {
        id: 81,
        modelno: "MT-555",
        URLname: "MT-555",
        name: "Combination Plier",
        img: "MT-555.webp",
        headname: "Pilers",
        OVERALLLENGTHTitle: "Overall Length: ",
        OVERALLLENGTHT: "8.",
        CapacityTitle: "Capacity: ",
        capacity: "208mm.",
        material: "High Grade Chrome-Vanadium Steel.",
        handeltitle: "Handle: ",
        handel: "Heavy duty CA-FR sleeves.",
        ApplicatonTitle: "Application",
        Applicaton: "Can cut heavy duty electrical Wires.",
        URL: "pilerbanner.webp",
        category: "Pilers",
        features: "1.Forged out of high-grade chrome vanadium steel.",
        features1: "2.Jaws duly induction hardened.",
        features2:
            "3.Injection moulded heavy duty CA-FR sleeves with anti-slip and high hand grip to prevent slipping.",
        features4: "4.Insulated sleeves tested for 2500 volts.",
    },
    {
        id: 82,
        modelno: "MT-535",
        URLname: "MT-535",
        name: "Long Nose Piler",
        img: "MT-535.webp",
        headname: "Pilers",
        OVERALLLENGTHTitle: "Overall Length: ",
        OVERALLLENGTHT: "7.",
        CapacityTitle: "Capacity: ",
        capacity: "117mm.",
        material: "Chrome vanadium steel.",
        handeltitle: "Handle: ",
        handel: "Heavy duty CA-FR sleeves.",
        ApplicatonTitle: "Application",
        Applicaton: "Can hold and cut fine electrical wire.",
        URL: "pilerbanner.webp",
        category: "Pilers",
        features: "1.Forged out of chrome vanadium steel.",
        features1: "2.Induction hardened jaws.",
        features2:
            "3.Fine serrations diagonally opposite enabling gripping of thinnest Wires and components.",
        features3: "4.Injection moulded sleeves.",
    },
    // cable cutter
    {
        id: 83,
        modelno: "CC-200",
        URLname: "CC-200",
        name: "Cable Cutter",
        img: "CC-200.webp",
        headname: "Cable Cutter",
        sizetitle: "Size: ",
        size: "7.",
        CapacityTitle: "Capacity: ",
        capacity: "10 mm Cable.",
        material: "High Grade Alloy Steel.",
        handeltitle: "Handle: ",
        handel: "PVC.",
        URL: "cablecutterbanner.webp",
        category: "Cable Cutter",
        features: "Coaxial cable & wire cutter with lock",
    },

    {
        id: 87,
        URLname: "Torx-Drive-Bits",
        name: "Torx Drive Bits",
        img: "TORX POWER DRIVE BITS.webp",
        powerbitname: "Torx drive bits",
        headname: "Power bits",
        sizetitle: "Drive Size:",
        size: "T5 TO T40",
        category: "Power bits",
        OVERALLLENGTHTitle: "Length: ",
        OVERALLLENGTHT: "50mm, 75mm, 100mm, 150mm",
        material: "CVM Alloy Steel.",
        URL: "powerbitsbanner.webp",
        features: "1.Configuration - single ended.",
        features1: "2.Shank size - 6.35 mm A/F.",
        features2: "3.Hardened and tempered to 58-60 HRc.",
    },
    {
        id: 88,
        URLname: "Allen-Power-Drive-Bits",
        name: "Allen Power Drive Bits",
        img: "ALLEN POWER DRIVE BITS.webp",
        category: "Power bits",
        powerbitname: "Allen drive bits",
        headname: "Power bits",
        sizetitle: "Drive Size:",
        size: "A15 TO A6 & BA4, BA, BA6",
        OVERALLLENGTHTitle: "Length: ",
        OVERALLLENGTHT: "50mm, 75mm, 100mm, 150mm",
        material: "CVM Alloy Steel.",
        URL: "powerbitsbanner.webp",
        features: "1.Configuration - single ended.",
        features1: "2.Shank size - 6.35 mm A/F.",
        features2: "3.Hardened and tempered to 58-60 HRc.",
    },
    {
        id: 89,
        URLname: "Slotted-Power-Drive-Bits",
        name: "Slotted Power Drive Bits",
        img: "SLOTTED POWER DRIVE BITS.webp",
        category: "Power bits",
        powerbitname: "Allen drive bits",
        headname: "Power bits",
        sizetitle: "Drive Size:",
        size: "S3 TO S7",
        OVERALLLENGTHTitle: "Length: ",
        OVERALLLENGTHT: "50mm, 75mm, 100mm, 150mm",
        material: "CVM Alloy Steel.",
        URL: "powerbitsbanner.webp",
        features: "1.Configuration - single ended.",
        features1: "2.Shank size - 6.35 mm A/F.",
        features2: "3.Hardened and tempered to 58-60 HRc.",
    },
    // MISCELLANEOUSTOOLS
    {
        id: 90,
        URLname:
            "Multipurpose-Magnetic-Stripholder-Available-in-Model-No-MS-12-MS-18-(inches)",
        name: " Multipurpose Magnetic Stripholder Available in Model No. MS-12 (inches) and MS-18 (inches)",
        img: "MS-18.webp",
        category: "MiscellaneousTools",
        modelno: "MS-18",
        headname: "Micellaneous Tools",
        URL: "mislanioustoolbanner.webp",
        features: "1.For hanging tools, kitchen knives, scissors, key etc.",
        // features1: "2.Shank size - 6.35 mm A/F.",
        // features2: "3.Hardened and tempered to 58-60 HRc.",
    },

    {
        id: 95,
        URLname: "Keyring-with-2-in-1-Reversible-Screw-Driver-Bit.",
        name: "Keyring with 2 in 1 Reversible Screw Driver Bit.",
        img: "KR-6045-02.webp",
        category: "MiscellaneousTools",
        modelno: "KR-045",
        headname: "Micellaneous Tools",
        URL: "mislanioustoolbanner.webp",
        features:
            "1.Multipurpose key ring for holding key up to 6 piece along with a two in one reversible torx screwdriver bit for normal emergency use",
        features1: "2.Most commonly used screwdriver head T8 and t15",
        features2:
            "3.Quick Reversing and ideal for working in short small space available in other screwdriver head on demand",
    },
    {
        id: 96,
        URLname: "Lawn-Edge-Cleaner",
        name: "Lawn Edge Cleaner",
        img: "mts-101.jpg",
        category: "Quick-fix Multi-tool",
        modelno: "MTS-101",
        headname: "Quick-fix Multi-tool",
        URL: "quickfix-multitools.jpeg",
        features: [
            "Made out of High-Grade Carbon Steel",
            "Duly heat-treated for durability ",
            "Serrated pronounced edge for effortless working ",
            "Coated for long life",
            "Fitted with grip for quick-fix onto four lengths of handles",
        ],
        application: ["Designed to neatly clean and straighten lawn edges"],
    },
    {
        id: 97,
        URLname: "Small-Weeding-Cultivator",
        name: "Small Weeding Cultivator",
        img: "mts-102.jpg",
        category: "Quick-fix Multi-tool",
        modelno: "MTS-102",
        headname: "Quick-fix Multi-tool",
        URL: "quickfix-multitools.jpeg",
        features: [
            "Made out of High-Grade Carbon Steel ",
            "Duly heat-treated for durability ",
            "Tough tines to loosen soil ",
            "Coated for long life",
            "Fitted with grip for quick-fix onto four lengths of handles",
        ],
        application: [
            "Handy tool for weeding, hoeing and making trenches",
            "Suitable in gardens",
            "fields in and around bushy shrubs and plants ",
        ],
    },
    {
        id: 98,
        URLname: "Cultivator-cum-Weeder ",
        name: "Cultivator cum Weeder ",
        img: "mts-103.jpeg",
        category: "Quick-fix Multi-tool",
        modelno: "MTS-103",
        headname: "Quick-fix Multi-tool",
        URL: "quickfix-multitools.jpeg",
        features: [
            "Made out of High-Grade Carbon Steel",
            "Duly heat-treated for durability ",
            " Tough tines to loosen soil  ",
            "Coated for long life",
            "Fitted with grip for quick-fix onto four lengths of handles",
        ],
        application: [
            "Sturdy tool for weeding, hoeing and making trenches",
            "Suitable in gardens, fields in and around bushy shrubs and plants",
        ],
    },
    {
        id: 99,
        URLname: "Soil-Aerator",
        name: "Soil Aerator",
        img: "mts-104.jpg",
        category: "Quick-fix Multi-tool",
        modelno: "MTS-104",
        headname: "Quick-fix Multi-tool",
        URL: "quickfix-multitools.jpeg",
        features: [
            "Made out of High-Grade Carbon Steel",
            "Duly heat-treated for durability ",
            "Coated for long life",
            "Fitted with grip for quick-fix onto four lengths of handles",
        ],
        application: [
            "Designed for easy loosening and ventilation of soils",
            "Effortless use in narrow bed rows capacitating optimum supply of nutrients",
            "Back face angle of the tool head effortlessly draw through the soil",
        ],
    },
    {
        id: 100,
        URLname: "Ridge-Plough",
        name: "Ridge Plough ",
        img: "mts-105.jpg",
        category: "Quick-fix Multi-tool",
        modelno: "MTS-105",
        headname: "Quick-fix Multi-tool",
        URL: "quickfix-multitools.jpeg",
        features: [
            "Made out of High-Grade Carbon Steel",
            "Duly heat-treated for durability ",
            "Intricately designed curvatures for effortless working in fields ",
            "Coated for long life",
            "Fitted with grip for quick-fix onto four lengths of handles",
        ],
        application: [
            "For making ridges, furrows and irrigation channels in fields",
            "Easy to use for large scale vegetable growers",
        ],
    },
    {
        id: 101,
        URLname: "Garden-Tiller",
        name: "Garden Tiller",
        img: "mts-106.jpg",
        category: "Quick-fix Multi-tool",
        modelno: "MTS-106",
        headname: "Quick-fix Multi-tool",
        URL: "quickfix-multitools.jpeg",
        features: [
            "Made out of High-Grade Carbon Steel",
            "Duly heat-treated for durability ",
            "Tough rotating tines and weeder to weed and loosen soil ",
            "Coated for long life",
            "Fitted with grip for quick-fix onto four lengths of handles ",
        ],
        application: [
            "Designed for weeding and breaking-up hard, compact soil into loose, broken-up  soil for easy planting",
        ],
    },
    {
        id: 102,
        URLname: "Dutch-Hoe",
        name: "Dutch-Hoe",
        img: "mts-107.jpg",
        category: "Quick-fix Multi-tool",
        modelno: "MTS-107",
        headname: "Quick-fix Multi-tool",
        URL: "quickfix-multitools.jpeg",
        features: [
            "Made out of High-Grade Carbon Steel",
            "Duly heat-treated for durability ",
            "Sharp ends ",
            "Coated for long life",
            "Fitted with grip for quick-fix onto four lengths of handles",
        ],
        application: ["Designed for weeding with minimal pressure"],
    },
    {
        id: 103,
        URLname: "Hoe-2-Prong",
        name: "Hoe-2 Prong ",
        img: "mts-108.jpg",
        category: "Quick-fix Multi-tool",
        modelno: "MTS-108",
        headname: "Quick-fix Multi-tool",
        URL: "quickfix-multitools.jpeg",
        features: [
            "Made out of High-Grade Carbon Steel",
            "Duly heat-treated for durability ",
            "Tough 2 Prong Rake and pointed Hoe  ",
            "Coated for long life",
            "Fitted with grip for quick-fix onto four lengths of handles",
        ],
        application: [
            "Prongs used for loosening the soil",
            "Opposite side Hoe used for hoeing-cultivating and weeding of the soil",
            "Suitable for pulling seed grooves",
        ],
    },
    {
        id: 104,
        URLname: "Hoe-3-Prong",
        name: "Hoe-3 Prong",
        img: "mts-109.jpg",
        category: "Quick-fix Multi-tool",
        modelno: "MTS-109",
        headname: "Quick-fix Multi-tool",
        URL: "quickfix-multitools.jpeg",
        features: [
            "Made out of High-Grade Carbon Steel",
            "Duly heat-treated for durability ",
            "Tough 3 Prong Rake and Hoe ",
            "Coated for long life",
            "Fitted with grip for quick-fix onto four lengths of handles",
        ],
        application: [
            "Prongs used for loosening the soil",
            "Opposite side Hoe used for hoeing-cultivating and weeding of the soil",
        ],
    },
    {
        id: 105,
        URLname: "Hoe-3-Prong-HD",
        name: "Hoe-3 Prong HD",
        img: "mts-110.jpg",
        category: "Quick-fix Multi-tool",
        modelno: "MTS-110",
        headname: "Quick-fix Multi-tool",
        URL: "quickfix-multitools.jpeg",
        features: [
            "Made out of High-Grade Carbon Steel",
            "Duly heat-treated for durability ",
            "Heavy Duty tough 3 Prong Rake and Hoe ",
            "Coated for long life",
            "Fitted with grip for quick-fix onto four lengths of handles",
        ],
        application: [
            "Heavy duty Prongs used for loosening the tough soil",
            "Opposite side Hoe used for hoeing-cultivating and weeding of the soil",
        ],
    },
    {
        id: 106,
        URLname: "Draw Hoe",
        name: "Draw Hoe",
        img: "mts-111.jpg",
        category: "Quick-fix Multi-tool",
        modelno: "MTS-111",
        headname: "Quick-fix Multi-tool",
        URL: "quickfix-multitools.jpeg",
        features: [
            "Made out of High-Grade Carbon Steel",
            "Duly heat-treated for durability ",
            "Sharp edge Blade",
            "Coated for long life",
            "Fitted with grip for quick-fix onto four lengths of handles",
        ],
        application: [
            "Designed for weeding under and between plants with its extra sharp blades",
        ],
    },
    {
        id: 107,
        URLname: "Weed-Extractor",
        name: "Weed Extractor",
        img: "mts-112.jpg",
        category: "Quick-fix Multi-tool",
        modelno: "MTS-112",
        headname: "Quick-fix Multi-tool",
        URL: "quickfix-multitools.jpeg",
        features: [
            "Made out of High-Grade Carbon Steel",
            "Duly heat-treated for durability ",
            "Sharp pointed contoured knife end  ",
            "Coated for long life",
            "Fitted with grip for quick-fix onto four lengths of handles",
        ],
        application: [
            "Designed for removing deep rooted weeds from base",
            "Knife end is inserted by thrusting into the soil exerting force on foot rest. The weed is pulled out without harming the plant. ",
        ],
    },
    {
        id: 108,
        URLname: "Swoe-Weeder",
        name: "Swoe Weeder",
        img: "mts-113.jpg",
        category: "Quick-fix Multi-tool",
        modelno: "MTS-113",
        headname: "Quick-fix Multi-tool",
        URL: "quickfix-multitools.jpeg",
        features: [
            "Made out of High-Grade Carbon Steel",
            "Duly heat-treated for durability ",
            "Three-way sharp edges  ",
            "Coated for long life",
            "Fitted with grip for quick-fix onto four lengths of handles",
        ],
        application: [
            "Designed for weeding from roots by push and pull operation ",
        ],
    },
    {
        id: 109,
        URLname: "Push-Pull-Weeder",
        name: "Push Pull Weeder",
        img: "mts-114.jpg",
        category: "Quick-fix Multi-tool",
        modelno: "MTS-114",
        headname: "Quick-fix Multi-tool",
        URL: "quickfix-multitools.jpeg",
        features: [
            "Made out of High-Grade Carbon Steel",
            "Duly heat-treated for durability ",
            "Two-way sharp corrugated blades ",
            "Coated for long life",
            "Fitted with grip for quick-fix onto four lengths of handles",
        ],
        application: [
            "Designed to weed light and sandy soils effortlessly",
            "Two-way blades cut through weeds on the pull and push stroke below the soil",
        ],
    },
    {
        id: 110,
        URLname: "Soil Rake",
        name: "Soil Rake",
        img: "mts-115.jpg",
        category: "Quick-fix Multi-tool",
        modelno: "MTS-115",
        headname: "Quick-fix Multi-tool",
        URL: "quickfix-multitools.jpeg",
        features: [
            "Made out of High-Grade Carbon Steel",
            "Duly heat-treated for durability ",
            "High strength 14 teeth Rake ",
            "Coated for long life",
            "Fitted with grip for quick-fix onto four lengths of handles",
        ],
        application: [
            " For clearing large field beds effortlessly",
            "For heavy duty works",
        ],
    },
    {
        id: 111,
        URLname: "Bow-Rake",
        name: "Bow Rake",
        img: "mts-116.jpg",
        category: "Quick-fix Multi-tool",
        modelno: "MTS-116",
        headname: "Quick-fix Multi-tool",
        URL: "quickfix-multitools.jpeg",
        features: [
            "Made out of High-Grade Carbon Steel",
            "Duly heat-treated for durability ",
            "High strength 16 teeth Rake ",
            "Coated for long life",
            "Fitted with grip for quick-fix onto four lengths of handles",
        ],
        application: [
            "For clearing tough large field beds effortlessly",
            "For heavy duty works",
        ],
    },
    {
        id: 112,
        URLname: "Trowel",
        name: "Trowel",
        img: "mts-117.jpg",
        category: "Quick-fix Multi-tool",
        modelno: "MTS-117",
        headname: "Quick-fix Multi-tool",
        URL: "quickfix-multitools.jpeg",
        features: [
            "Made out of High-Grade Carbon Steel",
            "Duly heat-treated for durability ",
            "Strong pointed trowel for field work ",
            "Coated for long life",
            "Fitted with grip for quick-fix onto four lengths of handles",
        ],
        application: ["Designed for removing soil and repot plants"],
    },
    {
        id: 113,
        URLname: "Fork",
        name: "Fork",
        img: "mts-118.jpg",
        category: "Quick-fix Multi-tool",
        modelno: "MTS-118",
        headname: "Quick-fix Multi-tool",
        URL: "quickfix-multitools.jpeg",
        features: [
            "Made out of High-Grade Carbon Steel",
            "Duly heat-treated for durability ",
            "Strong Tines ",
            "Coated for long life",
            "Fitted with grip for quick-fix onto four lengths of handles",
        ],
        application: [
            "Designed for breaking-up soil, turning over new beds and moving plants with large roots ",
        ],
    },
    {
        id: 114,
        URLname: "Handle-1-25",
        name: "Handle-1.25",
        img: "mts-hdl-15.jpg",
        category: "Quick-fix Multi-tool",
        modelno: "MTS-HDL-15",
        headname: "Quick-fix Multi-tool",
        URL: "quickfix-multitools.jpeg",
        features: [
            "Made out of light weight heavy duty Aluminium pipe",
            "Coated for long life",
            "Fitted with Quick-fix system",
        ],
        application: [
            "For holding attachments MTS101 to MTS118 for easy works in small gardens and fields",
        ],
        technical: ["Length 15"],
    },
    {
        id: 115,
        URLname: "D-Grip-Handle-2-5",
        name: "D-Grip Handle 2.5",
        img: "mts-hdl-30.jpg",
        category: "Quick-fix Multi-tool",
        modelno: "MTS-HDL-30",
        headname: "Quick-fix Multi-tool",
        URL: "quickfix-multitools.jpeg",
        features: [
            "Made out of light weight heavy duty Aluminium pipe ",
            "Coated for long life",
            "D-Grip for comfortable and easy working",
            "Fitted with Quick-fix system",
        ],
        application: [
            "For holding attachments MTS101 to MTS118 for works in gardens and fields",
        ],
        technical: ["Length 30"],
    },
    {
        id: 116,
        URLname: "D-Grip-Handle-4-0",
        name: "D-Grip Handle 4.0",
        img: "mts-hdl-48.jpg",
        category: "Quick-fix Multi-tool",
        modelno: "MTS-HDL-48",
        headname: "Quick-fix Multi-tool",
        URL: "quickfix-multitools.jpeg",
        features: [
            "Made out of light weight heavy duty Aluminium pipe ",
            "Coated for long life ",
            "D-Grip for comfortable and easy working",
            "Fitted with Quick-fix system",
        ],
        application: [
            "For holding attachments MTS101 to MTS118 for works in large gardens and fields",
        ],
        technical: ["Length 48"],
    },
    {
        id: 117,
        URLname: "D-Grip-Handle-5-0",
        name: "D-Grip Handle 5.0",
        img: "mts-hdl-60.jpg",
        category: "Quick-fix Multi-tool",
        modelno: "MTS-HDL-60",
        headname: "Quick-fix Multi-tool",
        URL: "quickfix-multitools.jpeg",
        features: [
            "Made out of light weight heavy duty Aluminium pipe",
            "Coated for long life",
            "Fitted with Quick-fix system",
        ],
        application: [
            "For holding attachments MTS101 to MTS118 for very heavy works in gardens and fields",
        ],
        technical: ["Length 60"],
    },
    {
        id: 119,
        modelno: "PSA-01",
        URLname: "Anvil-Pruning-Shear-7-1-2",
        name: "Anvil Pruning Shear – 7.1/2",
        img: "PSA-01.jpg",
        features: [
            "Blade made out of stainless steel",
            "Double heat-treated blade for extra power",
            "Laser beam checked cutting edge profile for homogenous sharpness",
            "Ergonomic handles for fatigue free long duration work",
            "Easy safety locking system",
        ],
        application: [
            "Pruning of both dry and green wood",
            "An evergreen pruning shear for all to use at home/garden/orchard",
        ],
        technical: ["Cutting Capacity: 15mm diameter", "Overall length: 190mm"],
        URL: "Collase of Garden tools.webp",
        category: "Garden & Agro Tools",
    },
    {
        id: 120,
        modelno: "PSA-02",
        URLname: "Anvil-Pruning-Shear-8-1-4",
        name: "Anvil Pruning Shear - 8.1/4",
        img: "PSA-02.jpg",
        features: [
            "Blade made out of high-performance stainless steel",
            "Double heat-treated blade for extra power",
            "Laser beam checked cutting edge profile for homogenous sharpness",
            "Ergonomic handles for fatigue free long duration work",
            "Easy safety locking system",
        ],
        application: [
            "Pruning of both dry and green wood",
            "An evergreen pruning shear for all to use at home/garden/orchard",
        ],
        technical: ["Cutting Capacity: 20mm diameter", "Overall Length: 210mm"],
        URL: "Collase of Garden tools.webp",
        category: "Garden & Agro Tools",
    },
    {
        id: 121,
        modelno: "PSA-06",
        URLname: "Anvil-Pruning-Shearr-Bent-Type-8-1-4",
        name: "Anvil Pruning Shear(Bent Type) - 8.1/4",
        img: "PSA-06.jpg",
        features: [
            "Blade made out of high-performance stainless steel",
            "Double heat-treated blade for extra power",
            "Laser beam checked cutting edge profile for homogenous sharpness",
            "Ergonomic handles for fatigue free long duration work",
            "Bent handle for extra grip",
            "Easy safety locking system",
        ],
        application: [
            "Pruning of both dry and green wood",
            "An evergreen pruning shear for all to use at home/garden/orchard",
        ],
        technical: ["Cutting Capacity: 20mm diameter", "Overall Length: 210mm"],
        URL: "Collase of Garden tools.webp",
        category: "Garden & Agro Tools",
    },
    {
        id: 122,
        modelno: "PSA-10",
        URLname: "Curved-Anvil-Pruning-Shear",
        name: "Curved Anvil Pruning Shear",
        img: "PSA-10.jpg",
        features: [
            "Blade made out of high-performance stainless steel",
            "Double heat-treated blade for extra power",
            "Laser beam checked cutting edge profile for homogenous sharpness",
            "Curved Anvil",
            "Ergonomic handles for fatigue free long duration work",
            "Easy safety locking system",
        ],
        application: [
            "Pruning of green wood ",
            "A suitable pruning shear widely used in Apple Orchards",
        ],
        technical: ["Cutting Capacity: 15mm diameter", "Overall Length: 190mm"],
        URL: "Collase of Garden tools.webp",
        category: "Garden & Agro Tools",
    },
    {
        id: 123,
        modelno: "M35-Xpert",
        URLname: "High-Grip-Anvil-Pruning-Shear",
        name: "High Grip Anvil Pruning Shear ",
        img: "M35-Xpert.jpg",
        features: [
            "Blade made out of high-performance stainless steel ",
            "Double heat-treated blade for extra power",
            "Laser beam checked cutting edge profile for homogenous sharpness",
            "High Grip ergonomic handles for excellent grip and fatigue free long duration work ",
            "Easy safety locking system",
        ],
        application: [
            "Pruning of both dry and green wood",
            "An evergreen pruning shear for all to use at home/garden/orchard",
        ],
        technical: ["Cutting Capacity: 20mm diameter", "Overall Length: 220mm"],
        URL: "Collase of Garden tools.webp",
        category: "Garden & Agro Tools",
    },
    {
        id: 124,
        modelno: "PSB-03",
        URLname: "Heavy-Duty-By-pass-Pruning-Shear-PSB-03",
        name: "Heavy Duty By-pass Pruning Shear",
        img: "PSB-03.jpg",
        features: [
            "Blade made out of high-performance stainless steel",
            "Double heat-treated blade for extra power",
            "Laser beam checked cutting edge profile for homogenous sharpness",
            "Clean and precise cut does not damage and create stress to the plant",
            "Ergonomic handles for fatigue free long duration work",
            "Heavy duty aluminum die cast handles in bright silver fluorescent colour.",
            "Easy safety locking system",
            "Antique look",
            "A must for Gardener’s collection",
        ],
        application: ["Pruning of green wood"],
        technical: ["Cutting Capacity: 20mm diameter", "Overall Length: 210mm"],
        URL: "Collase of Garden tools.webp",
        category: "Garden & Agro Tools",
    },
    {
        id: 125,
        modelno: "PSB-04",
        URLname: "By-pass-Pruning-Shear-PSB-04",
        name: "By-pass Pruning Shear",
        img: "PSB-04.jpg",
        features: [
            "Blade made out of high-performance stainless steel",
            "Double heat-treated blade for extra power",
            "Laser beam checked cutting edge profile for homogenous sharpness",
            "Clean and precise cut does not damage and create stress to the plant",
            "Ergonomic handles for fatigue free long duration work",
            "Virgin polymer light weight handles for daily use",
            "Easy safety locking system",
            "A must for Kitchen Garden use.",
        ],
        application: ["Pruning of green wood"],
        technical: ["Cutting Capacity: 15mm diameter", "Overall Length: 210mm"],
        URL: "Collase of Garden tools.webp",
        category: "Garden & Agro Tools",
    },
    {
        id: 126,
        modelno: "PSB-05",
        URLname: "Heavy-Duty-By-pass-Pruning-Shear-PSB-05",
        name: "Heavy Duty By-pass Pruning Shear",
        img: "PSB-05.jpg",
        features: [
            "Blade made out of high-performance stainless steel",
            "Double heat-treated blade for extra power",
            "Clean and precise cut does not damage and create stress to the plant",
            "Laser beam checked cutting edge profile for homogenous sharpness",
            "Ergonomic handles for fatigue free long duration work",
            "Rubber handles for comfortable grip",
            "Easy safety locking system",
        ],
        application: ["Pruning of green wood"],
        technical: ["Cutting Capacity: 19mm diameter", "Overall Length: 190mm"],
        URL: "Collase of Garden tools.webp",
        category: "Garden & Agro Tools",
    },
    {
        id: 127,
        modelno: "PSB-07",
        URLname: "Auto-unlock-By-pass-Pruning-Shear",
        name: "Auto-unlock By-pass Pruning Shear",
        img: "PSB-07.jpg",
        features: [
            "Blade made out of High Carbon Steel",
            "Double heat-treated blade for extra power",
            "Laser beam checked cutting edge profile for homogenous sharpness",
            "Clean and precise cut does not damage and create stress to the plant",
            "Ergonomic handles for fatigue free long duration work",
            "Bright coloured plastisol sleeves on Aluminium die cast handles",
            "Auto unlocking when held in palm and pressed ",
            "Easy safety locking system",
            "A must for Gardener’s collection",
        ],
        application: ["Pruning of green wood"],
        technical: ["Cutting Capacity: 19mm diameter", "Overall Length: 210mm"],
        URL: "Collase of Garden tools.webp",
        category: "Garden & Agro Tools",
    },
    {
        id: 128,
        modelno: "PSB-08",
        URLname: "Heavy-Duty-By-pass-Pruning-Shear-PSB-08",
        name: "Heavy Duty By-pass Pruning Shear",
        img: "PSB-08.jpg",
        features: [
            "Blade made out of high-performance stainless steel ",
            "Double heat-treated blade for extra power",
            "Laser beam checked cutting edge profile for homogenous sharpness",
            "Clean and precise cut does not damage and create stress to the plant ",
            "Ergonomic handles for fatigue free long duration work",
            "Nylon handles contoured for comfortable grip",
            "Easy safety locking system",
        ],
        application: [
            "Pruning of green wood ",
            "Widely used in Sericulture, Apple Orchards",
        ],
        technical: ["Cutting Capacity: 19mm diameter", "Overall Length: 230mm"],
        URL: "Collase of Garden tools.webp",
        category: "Garden & Agro Tools",
    },
    {
        id: 129,
        modelno: "PSB-09",
        URLname: "By-pass-Pruning-Shear-PSB-09",
        name: "By-pass Pruning Shear",
        img: "PSB-09.jpg",
        features: [
            "Blade made out of high-grade stainless steel ",
            "Double heat-treated blade for extra power",
            "Laser beam checked cutting edge profile for homogenous sharpness",
            "Clean and precise cut does not damage and create stress to the plant ",
            "Ergonomic handles for fatigue free long duration work",
            "Sleek design",
            "Suitable for light duty work",
            "Easy safety locking system",
        ],
        application: [" Pruning of green branches"],
        technical: ["Cutting Capacity: 14mm diameter", "Overall Length: 190mm"],
        URL: "Collase of Garden tools.webp",
        category: "Garden & Agro Tools",
    },
    {
        id: 130,
        modelno: "AHS-10",
        URLname: "Hedge-Shear-with-Aluminium-Handle",
        name: "Hedge Shear with Aluminium Handle",
        img: "AHS-10.jpg",
        features: [
            "Blades made out of High-Grade Alloy Steel",
            "Double Heat-treated blades for toughness and retention of sharp edges",
            "Blade edges duly sharpened",
            "Anti-rust auto black surface finish of blades",
            "Light weight Handles made out of heavy-duty Aluminium pipes",
            "Comfortable rubberised grips",
            "Links designed to withstand load",
            "Stoppers material sustains impact load",
            "Rivetted link-handle prevents loosening of handle with link",
        ],
        application: ["Trimming of bushes", "Creating and maintaining hedges"],
        technical: ["Overall Length: 580mm (approx.)"],
        URL: "Collase of Garden tools.webp",
        category: "Garden & Agro Tools",
    },
    {
        id: 131,
        modelno: "PHS-10",
        URLname: "Hedge-Shear-with-Plastic-Moulded-Handles",
        name: "Hedge Shear with Plastic Moulded Handles",
        img: "PHS-10.jpg",
        features: [
            "Blades made out of High-Grade Alloy Steel",
            "Double Heat-treated blades for toughness and retention of sharp edges",
            "Blade edges duly sharpened",
            "Anti-rust auto black surface finish of blades",
            "Virgin Plastic moulded handles",
            "Contoured profile for comfortable grip and long duration work",
            "Links designed to withstand load",
            "Stoppers material sustains impact load",
            "Rivetted link-handle prevents loosening of handle with link",
        ],
        application: ["Trimming of bushes", "Creating and maintaining hedges"],
        technical: ["Overall length: 545mm(approx.)"],
        URL: "Collase of Garden tools.webp",
        category: "Garden & Agro Tools",
    },
    {
        id: 132,
        modelno: "WHS-10",
        URLname: "Hedge-Shear-with-Wooden-Handle",
        name: "Hedge Shear with Wooden Handle",
        img: "WHS-10.jpg",
        features: [
            "Blades made out of High-Grade Alloy Steel",
            "Double Heat-treated blades for toughness and retention of sharp edges",
            "Blade edges duly sharpened",
            "Anti-rust auto black surface finish of blades",
            "Wooden Handles with contoured profile for comfortable grip in hands",
            "Links designed to withstand load",
            "Stoppers material sustains impact load",
            "Rivetted link-handle prevents loosening of handle with link",
        ],
        application: ["Trimming of bushes", "Creating and maintaining hedges"],
        technical: ["Overall Length: 575mm (approx.)"],
        URL: "Collase of Garden tools.webp",
        category: "Garden & Agro Tools",
    },
    {
        id: 133,
        modelno: "AHS-10L",
        URLname: "Long-Handle-Aluminium-Handle-Hedge-Shear",
        name: "Long Handle Aluminium Handle Hedge Shear",
        img: "AHS-10L.jpg",
        features: [
            "Blades made out of High-Grade Alloy Steel",
            "Double Heat-treated blades for toughness and retention of sharp edges",
            "Blade edges duly sharpened",
            "Anti-rust auto black surface finish of blades",
            "Long Handles for easy reach to far-off bushes",
            "Light weight Handles made out of heavy-duty Aluminium pipes",
            "Comfortable rubberised grips",
            "Links designed to withstand load",
            "Stoppers material sustains impact load",
            "Rivetted link-handle prevents loosening of handle with link",
        ],
        application: [
            "Trimming of bushes",
            "Creating and maintaining hedges",
            "Long handles trim and shape far-off bushes",
        ],
        technical: ["Overall length: 870mm(approx.)"],
        URL: "Collase of Garden tools.webp",
        category: "Garden & Agro Tools",
    },
    {
        id: 134,
        modelno: "THS-10X",
        URLname: "Telescopic-Hedge-Shear",
        name: "Telescopic Hedge Shear",
        img: "THS-10X.jpg",
        features: [
            "Blades made out of High-Grade Alloy Steel",
            "Double Heat-treated blades for toughness and retention of sharp edges",
            "Blade edges duly sharpened",
            "Anti-rust auto black surface finish of blades",
            "Telescopic – Extendable Handles for easy reach to far of bushes",
            "Light weight Handles made out of heavy-duty Aluminium pipes",
            "Comfortable rubberised PU grips",
            "Links designed to withstand load",
            "Stoppers material sustains impact load",
            "Rivetted link-handle prevents loosening of handle with link",
        ],
        application: [
            "Trimming of bushes",
            "Creating and maintaining hedges",
            "Extendable handles trim and shape far-off bushes",
        ],
        technical: ["Overall length: 840mm(approx.)"],
        URL: "Collase of Garden tools.webp",
        category: "Garden & Agro Tools",
    },
    {
        id: 135,
        modelno: "AL-22",
        URLname: "Anvil-Lopper",
        name: "Anvil Lopper",
        img: "AL-22.jpg",
        features: [
            "Blade made out of SK5 Steel",
            "Dual heat-treated blades for high cutting performance",
            "Straight cutting edge for clear chopping/slicing at center with equally sturdy anvil as support",
            "Fixed, light weight handles made out of heavy-duty Aluminum pipe with comfort grips.",
            "Easy operation of extension of handles",
            "Aluminum handles are corrosion free",
            "Best suitable for dead wood. Can also be used for green wood",
        ],
        application: ["Cut unwanted branches and twigs"],
        precautions: [
            "Fully open jaw-anvil and place/hold job from below and then cut.",
            "Cut branches keeping both hands in symmetry. Do not twist the lopper while cutting",
        ],
        technical: [
            "Cutting Capacity: 40mm",
            "Overall Extendable length 30”/ 740mm (approx.)",
        ],
        URL: "Collase of Garden tools.webp",
        category: "Garden & Agro Tools",
    },
    {
        id: 136,
        modelno: "TAL-37X",
        URLname: "Telescopic-Extra-Leverage-Anvil-Lopper",
        name: "Telescopic Extra Leverage Anvil Lopper ",
        img: "TAL-37X.jpg",
        features: [
            "Blade made out of SK5 Steel",
            "Dual heat-treated blades for high cutting performance",
            "Straight cutting edge for clear chopping/slicing at center with equally sturdy anvil as support",
            "Telescopic/Extendable handles for extra leverage/reduced effort",
            "Light weight handles made out of heavy-duty Aluminum pipe with comfort grips",
            "Easy operation of extension of handles",
            "Aluminum handles are corrosion free",
            "Best suitable for dead wood. Can also be used for green wood.",
        ],
        application: [
            "Cut unwanted branches and twigs",
            "Extendable handles cut high reach branches and twigs easily",
        ],
        precautions: [
            "Fully open jaw-anvil and place/hold job from below and then cut.",
            "Cut branches keeping both hands in symmetry. Do not twist the lopper while cutting",
        ],
        technical: [
            "Cutting Capacity: 40mm",
            "Overall Extendable length 37”/ 950mm (approx.)",
        ],
        URL: "Collase of Garden tools.webp",
        category: "Garden & Agro Tools",
    },
    {
        id: 137,
        modelno: "BPL-14G",
        URLname: "Power-Geared-By-pass-Lopper",
        name: "Power Geared By-pass Lopper",
        img: "BPL-14G.jpg",
        features: [
            "Blade made out of SK-5 Steel",
            "Dual heat-treated blades for high cutting performance",
            "Single-edged blade slice past thick base as it closes.",
            "Clean and precise cut does not damage and create stress to the plant",
            "Geared for reduced effort.",
            "Easy operation of extension of handles",
            "Fixed, light weight handles made out of heavy-duty Aluminum pipe with comfortable grips",
            "Best suitable for green/live wood.",
        ],
        application: ["Cut unwanted branches and twigs"],
        precautions: [
            "Fully open jaw-anvil and place/hold job from below and then cut.",
            "Cut branches keeping both hands in symmetry. Do not twist the lopper while cutting",
        ],
        technical: ["Cutting Capacity: 30mm"],
        URL: "Collase of Garden tools.webp",
        category: "Garden & Agro Tools",
    },
    {
        id: 138,
        modelno: "BPL-22G",
        URLname: "Geared-Bypass-Lopper",
        name: "Geared By-pass Lopper",
        img: "BPL-22G.jpg",
        features: [
            "Blade made out of SK5 Steel",
            "Dual heat-treated blades for high cutting performance",
            "Single-edged blade slice past thick base as it closes",
            "Clean and precise cut does not damage and create stress to the plant",
            "Geared for reduced effort",
            "Fixed, light weight handles made out of heavy-duty Aluminum pipe with comfort grips",
            "Aluminum handles are corrosion free",
            "Best suitable for green/live wood.",
        ],
        application: ["Cut unwanted branches and twigs"],
        precautions: [
            "Fully open jaw-anvil and place/hold job from below and then cut.",
            "Cut branches keeping both hands in symmetry. Do not twist the lopper while cutting",
        ],
        technical: ["Cutting Capacity: 40mm", "Overall length 30”/750mm (approx.)"],
        URL: "Collase of Garden tools.webp",
        category: "Garden & Agro Tools",
    },
    {
        id: 139,
        modelno: "BPL-37XG",
        URLname: "Telescopic-Extra-Leverage-Geared-By-pass-Lopper",
        name: "Telescopic Extra Leverage Geared By-pass Lopper",
        img: "BPL-37XG.jpg",
        features: [
            "Blade made out of SK5 Steel",
            "Dual heat-treated blades for high cutting performance",
            "Single-edged blade slice past thick base as it closes.",
            "Clean and precise cut does not damage and create stress to the plant",
            "Telescopic/Extendable handles for extra leverage.",
            "Geared for reduced effort.",
            "Light weight handles made out of heavy-duty Aluminum pipe with comfort grips.",
            "Easy operation of extension of handles",
            "Aluminum handles are corrosion free",
            "Best suitable for green/live wood.",
        ],
        application: [
            "Cut unwanted branches and twigs",
            "Extendable handles cut high reach branches and twigs easily",
        ],
        precautions: [
            "Fully open jaw-anvil and place/hold job from below and then cut.",
            "Cut branches keeping both hands in symmetry. Do not twist the lopper while cutting",
        ],
        technical: [
            "Cutting Capacity: 40mm",
            "Overall Extendable length 37”/ 970mm (approx.)",
        ],
        URL: "Collase of Garden tools.webp",
        category: "Garden & Agro Tools",
    },
    {
        id: 140,
        modelno: "PL15G",
        URLname: "Power-Geared-Anvil-Lopper",
        name: "Power Geared Anvil Lopper",
        img: "PL-15G.jpg",
        features: [
            "Blade made out of Stainless Steel",
            "Special heat-treated blades for high cutting performance",
            "Straight cutting edge for clear chopping/slicing at center with equally sturdy anvil as support",
            "Clean and precise cut does not damage and create stress to the plant",
            "Geared for reduced effort",
            "Fixed, ultra-light weight handles made out of reinforced plastic for sustaining extra load",
            "Best suitable for all types of woods.",
        ],
        application: ["Cut unwanted branches and twigs"],
        precautions: [
            "Fully open jaw-anvil and place/hold job from below and then cut.",
            "Cut branches keeping both hands in symmetry. Do not twist the lopper while cutting",
        ],
        technical: ["Cutting Capacity: 30mm", "Overall length 15”/400mm (approx.)"],
        URL: "Collase of Garden tools.webp",
        category: "Garden & Agro Tools",
    },
    {
        id: 141,
        modelno: "HPS-21",
        URLname: "Foldable-Hand-Pruning-Saw",
        name: "Foldable Hand Pruning Saw",
        img: "HPS-21.jpg",
        features: [
            "Blade made out of High Carbon Steel",
            "Triple cut blades on both push & pull strokes",
            "Opens at three angles",
            "Easy press button to open and fold",
        ],
        application: ["Cut / prune branches"],
        technical: [
            "Cutting Capacity: 80mm ",
            "Pitch 7 teeth/inch",
            "Length of blade: 7inch",
        ],
        URL: "Collase of Garden tools.webp",
        category: "Garden & Agro Tools",
    },
    {
        id: 142,
        modelno: "HPS-22",
        URLname: "Hand-Pruning-Saw-with-Sheath",
        name: "Hand Pruning Saw with Sheath",
        img: "HPS-22.jpg",
        features: [
            "Blade made out of High Carbon Steel",
            "Triple cut blades on both push & pull strokes",
            "Safety Sheath for carrying and storing",
        ],
        application: ["Cut / prune branches"],
        technical: [
            "Cutting Capacity: 80mm ",
            "Pitch 7 teeth/inch",
            "Length of blade: 10”/250mm",
        ],
        URL: "Collase of Garden tools.webp",
        category: "Garden & Agro Tools",
    },
    {
        id: 143,
        modelno: "HPS-23",
        URLname: "Curve-Pruning-Saw ",
        name: "Curve Pruning Saw ",
        img: "HPS-23.webp",
        features: [
            "Blade made out of High Carbon Steel",
            "Triple cut blades on both push & pull strokes",
            "Comfort grip for cutting",
            "Provision for fitting with long telescopic handle for high reach",
            "Safety stroke and extra thrust blade at operating end",
            "Notch blade to shear off hanging ends",
        ],
        application: ["Cut / prune branches "],
        technical: [
            "Cutting Capacity: 100mm ",
            "Pitch 7 teeth/inch",
            "Length of blade: 16”/400mm",
        ],
        URL: "Collase of Garden tools.webp",
        category: "Garden & Agro Tools",
    },
    {
        id: 144,
        modelno: "MCP-31",
        URLname: "Coconut-Pruner",
        name: "Coconut Pruner",
        img: "MCP-31.jpg",
        features: [
            "Blade made out of Carbon Steel",
            "Double heat-treated blade for toughness and retention of sharp edge",
            "Duly sharpened for peeling and harvesting",
            "Light weight heavy duty Aluminium pipe handle with soft grip",
            "Ergonomic handles for fatigue free long duration work",
        ],
        application: ["Harvesting Coconut and Banana crops", "Peeling raw coconut"],
        URL: "Collase of Garden tools.webp",
        category: "Garden & Agro Tools",
    },
    {
        id: 145,
        modelno: "MCP-31L",
        URLname: "Coconut-Pruner-Long",
        name: "Coconut Pruner - Long",
        img: "MCP-31L.jpg",
        features: [
            "Blade made out of Carbon Steel",
            "Double heat-treated blade for toughness and retention of sharp edge",
            "Duly sharpened for peeling and harvesting",
            "Light weight heavy duty Aluminium pipe handle with soft grip",
            "Ergonomic handles for fatigue free long duration work",
            "Long handles for high reach",
        ],
        application: ["Harvesting Coconut and Banana crops", "Peeling raw coconut"],
        URL: "Collase of Garden tools.webp",
        category: "Garden & Agro Tools",
    },
    {
        id: 146,
        modelno: "HR-01",
        URLname: "Hand-Rake",
        name: "Hand Rake",
        img: "HR-01.jpg",
        features: [
            "Made out of Aluminium alloy",
            "Pressure die-cast for durability",
            "Ergonomic handles for fatigue free long duration work",
        ],
        application: [
            "Tough tines for loosening of soil and making beds for plantation",
        ],
        URL: "Collase of Garden tools.webp",
        category: "Garden & Agro Tools",
    },
    {
        id: 147,
        modelno: "TT-02",
        URLname: "Transplant Trowel",
        name: "Transplant-Trowel",
        img: "TT-02.jpg",
        features: [
            "Made out of Aluminium alloy",
            "Pressure die-cast for durability",
            "Ergonomic handles for fatigue free long duration work",
        ],
        application: [
            "Plant seeds in equal depth and repot without harming the roots",
        ],
        URL: "Collase of Garden tools.webp",
        category: "Garden & Agro Tools",
    },
    {
        id: 148,
        modelno: "HT-03",
        URLname: "Hand-Trowel",
        name: "Hand Trowel",
        img: "HT-03.jpg",
        features: [
            "Made out of Aluminium alloy",
            "Pressure die-cast for durability",
            "Ergonomic handles for fatigue free long duration work",
        ],
        application: ["For removing soils and repotting plant"],
        URL: "Collase of Garden tools.webp",
        category: "Garden & Agro Tools",
    },
    {
        id: 149,
        modelno: "CC-200",
        URLname: "cable-cutter",
        name: "Cable Cutter",
        img: "CC-200.jpg",
        "features": ["Made out of high-grade alloy steel", "Comfortable PVC sleeve, Easy lock for storing"],
        "application": ["Coaxial cable and wire cutter"],
        "technical": ["Cutting capacity: Φ 10 mm", "Overall length: 170 mm"],
        "URL": "Cable-Cutter.jpg",
        "category": "Cable Cutter"
    },
    {
        id: 150,
        modelno: "CC-200-18",
        URLname: "cable-cutter-1",
        name: "Cable Cutter",
        img: "CC-200-18.jpg",
        "features": ["Made out of high-grade alloy steel", "Ergonomic PVC grips, Easy lock for storing"],
        "application": ["Cable cutter"],
        "technical": ["Cutting capacity: Φ 18 mm", "Overall length: 200 mm"],
        "URL": "Cable-Cutter.jpg",
        "category": "Cable Cutter"
    },
    {
        id: 151,
        modelno: "CC-300",
        URLname: "micro-cutter",
        name: "Micro Cutter",
        img: "CC-300.jpg",
        "features": ["Made out of high-grade alloy steel, Ergonomic PVC handle grips"],
        "application": ["For cutting Copper/Aluminium conductor", "Easy cutting with one hand operation due to high transmission ratio"],
        "technical": ["Cutting capacity: up-to 24mm²", "Overall length: 300 mm"],
        "URL": "Cable-Cutter.webp",
        "category": "Cable Cutter"
    },
    {
        id: 153,
        modelno: "MT-06",
        URLname: "micro-shear",
        name: "Micro Shear",
        img: "MT-06.jpg",
        "features": ["Made out of alloy steel, Ergonomic PVC handle grips"],
        "application": ["Suitable for cutting wires"],
        "technical": ["Cutting capacity: Φ 0.8 mm to 1.4 mm", "Overall length: 125 mm"],
        "URL": "micro shears banner.webp",
        "category": "NIPPERS & MICRO SHEARS"
    },
    {
        id: 154,
        modelno: "MT-07",
        URLname: "nipper-cutter",
        name: "Nipper Cutter",
        img: "MT-07.jpg",
        "features": ["Made out of alloy steel, Dual tone 100% lead free polystyrene handle grips"],
        "application": ["A versatile tool for cutting Copper and Aluminium conductors"],
        "technical": ["Cutting capacity: Φ 0.8 mm to 1.2 mm", "Overall length 120 mm"],
        "URL": "micro shears banner.webp",
        "category": "NIPPERS & MICRO SHEARS"
    },
    {
        id: 155,
        modelno: "MT-010",
        URLname: "micro-cutter",
        name: "Micro Cutter",
        img: "MT-010.jpg",
        "features": ["Made out of alloy steel, Ergonomic PVC handle grips"],
        "application": ["Suitable to cut wires"],
        "technical": ["Cutting capacity: Φ 0.8 mm to 1.2 mm", "Overall length: 125 mm"],
        "URL": "micro shears banner.webp",
        "category": "NIPPERS & MICRO SHEARS"
    },
    {
        id: 156,
        modelno: "MT-011",
        URLname: "palm-nipper",
        name: "Palm Nipper",
        img: "MT-011.jpg",
        "features": ["Made out of high-grade alloy steel", "Ergonomic PVC handle grips", "Cutting edges duly ground and induction hardened"],
        "application": ["Suitable for cutting soft Copper and Aluminium wires"],
        "technical": ["Cutting capacity: Up-to Φ1.5 mm", "Overall length: 112 mm"],
        "URL": "micro shears banner.webp",
        "category": "NIPPERS & MICRO SHEARS"
    },
    {
        id: 157,
        modelno: "MT-012",
        URLname: "heavy-duty-diagonal-nipper",
        name: "Heavy Duty Diagonal Nipper",
        img: "MT-012.jpg",
        "features": ["Made out of high carbon alloy steel", "Ergonomic PVC cushioned grips", "Cutting edges induction hardened for long life"],
        "application": ["Suitable for cutting Copper & Aluminium conductors", "Widely used in Electronics, Telecommunication", "Computers & PCB production"],
        "technical": ["Stripping capacity: Up-to Φ 2.0 mm", "Overall length: 150 mm"],
        "URL": "micro shears banner.webp",
        "category": "NIPPERS & MICRO SHEARS"
    },
    {
        id: 158,
        modelno: "MT-535",
        URLname: "long-nose-plier",
        name: "Long Nose Plier",
        img: "MT-535.webp",
        "features": ["Forged out of high-grade Chrome-Vanadium steel", "Jaws duly induction hardened", "Fine serrations diagonally opposite enabling gripping of thinnest wires and components", "Injection moulded cellulose acetate sleeves"],
        "application": ["For cutting and holding fine electrical wires", "For electricians and workmen"],
        "technical": ["Overall length: 7”/180 mm"],
        "URL": "Pliers-and-Nose-Pliers.webp",
        "category": "Pliers and Nose Pliers"
    },

    {
        "id": 159,
        "model": "MT-555",
        "URLname": "combination-plier",
        "name": "Combination Plier",
        "img": "MT-555.webp",
        "features": ["Forged out of high-grade Chrome-Vanadium steel", "Jaws duly induction hardened", "Injection moulded heavy duty CA-FR anti-slip and high hand grip sleeves", "Plier insulated sleeves are tested for 2500 AC Volts"],
        "application": ["For bending and cutting through metal wire and electrical cables", "For electricians and workmen"],
        "technical": ["Overall length: 8”/200 mm"],
        "URL": "Pliers-and-Nose-Pliers.webp",
        "category": "Pliers and Nose Pliers"
    },
    {
        id: 160,
        modelno: "MT-02Dx",
        URLname: "Wire-Stripper",
        name: "Wire Stripper",
        img: "MT-02Dx.jpg",
        "features": ["Made out of Nylon body", "Smooth operation free from any hindrance"],
        "application": ["A unique tool to strip & cut insulation of copper wire ranging from 0.5mm to 2.5mm", "Suitable for electronics, aviation, automobile and day to day maintenance"],
        "technical": ["Cutting/stripping capacity: Copper wire Φ 14-24 AWG / 0.5 mm² - 6.0 mm², Overall length: 180 mm"],
        "URL": "WIRE-STRIPPER.webp",
        "category": "WIRE STRIPPER"
    },
    {
        id: 166,
        modelno: "MT-02Dx Plus",
        URLname: "Wire Stripper-1",
        name: "Wire Stripper",
        img: "MT-02Dx-Plus.jpg",
        "features": ["New improved engineering re-enforced plastic material body", "Smooth and fast operation", "free from any hindrance"],
        "application": ["Multitec 2-in-1 wire stripper and cutter strips wires and cables accurately and cleanly on both solid core and stranded wire.", "Suitable for electronics, aviation, automobile and day to day maintenance, Suitable fro stripping cable length capacity up-to 20 mm"],
        "technical": ["Cutting/stripping capacity: Copper wire Φ 14-24 AWG / 0.5 mm² - 6.0 mm²", "Overall length: 180 mm"],
        "URL": "WIRE-STRIPPER.webp",
        "category": "WIRE STRIPPER"
    },
    {
        id: 167,
        modelno: "MT-150B",
        URLname: "Wire-Stripper-&-Cutter",
        name: " Wire Stripper & Cutter",
        img: "MT-150B.jpg",
        "features": ["Made out of high-grade tempered steel", "Ergonomic PVC cushioned grips", "With screw gauge adjuster"],
        "application": ["Suitable to cut and strip electrical wires"],
        "technical": ["Stripping capacity: Copper wire up-to 4.0 mm²", "Overall length: 140 mm"],
        "URL": "WIRE-STRIPPER.webp",
        "category": "WIRE STRIPPER"
    },
    {
        id: 161,
        modelno: "MT-150C-Champion",
        URLname: "Wire-Stripper-&-Cutter-Champion",
        name: "Wire Stripper & Cutter (Champion)",
        img: "MT-150C-Champion.jpg",
        "features": ["Made out of high-grade tempered steel", "Heavy-duty construction with precision cutting edges", "Handles made of strong engineering plastic with extra hand grip", "With screw gauge adjuster"],
        "application": ["Suitable to cut and strip electrical wires"],
        "technical": ["Stripping capacity: Copper wire up-to Φ 4.0 mm², Overall length: 140 mm"],
        "URL": "WIRE-STRIPPER.webp",
        "category": "WIRE STRIPPER"
    },
    {
        id: 162,
        modelno: "MT-68C",
        URLname: "Wire-Stripper-&-Cutter",
        name: "Wire Stripper & Cutter",
        img: "MT-68C.jpg",
        "features": ["Made out of high-grade tempered steel", "Ergonomic PVC cushioned grips", "With screw gauge adjuster"],
        "application": ["Suitable to cut and strip electrical wires"],
        "technical": ["Stripping capacity: Copper wire up-to Φ 4.0 mm²", "Overall length: 140 mm"],
        "URL": "WIRE-STRIPPER.webp",
        "category": "WIRE STRIPPER"
    },
    {
        id: 163,
        modelno: "MT-68D",
        URLname: "Wire-Stripper-&-Cutter-1",
        name: "Wire Stripper & Cutter",
        img: "MT-68D.jpg",
        "features": ["Made out of high-grade tempered steel", "Duly ground mirror finished blades", "Ergonomic PVC cushioned grips", "With spring and screw gauge adjuster"],
        "application": ["Suitable to cut and strip electrical wires"],
        "technical": ["Stripping capacity: Copper wire up-to Φ 4.0 mm²", "Overall length: 140 mm"],
        "URL": "WIRE-STRIPPER.webp",
        "category": "WIRE STRIPPER"
    },
    {
        id: 164,
        modelno: "MT-101",
        URLname: "Medium-Duty-Wire-Stripper-&-Cutter",
        name: "Medium Duty Wire Stripper & Cutter",
        img: "MT-101.jpg",
        "features": ["Made out of high-grade tempered steel", "Duly ground mirror finished blades", "Ergonomic heavy duty PVC cushioned grips", "Has long wire cutter blades", "Cutting edges duly polished"],
        "application": ["Suitable to cut and strip Copper and Aluminium wires wires"],
        "technical": ["Stripping capacity: Copper and Aluminium wire up-to Φ 3.0 mm²", "Cutting capacity: ϕ 3.0 mm, Overall length: 127 mm"],
        "URL": "WIRE-STRIPPER.webp",
        "category": "WIRE STRIPPER"
    },
    {
        id: 165,
        modelno: "MT-501",
        URLname: "Heavy-Duty-Wire-Stripper-&-Cutter",
        name: "Heavy Duty Wire Stripper & Cutter",
        img: "MT-501.jpg",
        "features": ["Made out of high-grade tempered steel", "Duly induction hardened", "Ergonomic heavy duty PVC cushioned grips", "With gauge adjuster"],
        "application": ["Suitable to cut and strip heavy duty electrical wires and cables"],
        "technical": ["Stripping capacity: 2.1 SWG to 6.0 SWG", "Cutting capacity: ϕ 3.0 mm", "Overall length: 170 mm"],
        "URL": "WIRE-STRIPPER.webp",
        "category": "WIRE STRIPPER"
    },

];
